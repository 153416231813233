@font-face {
  font-family: 'Gotham Pro';
  font-display: fallback;
  src: url("GothaProReg.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro Bold';
  font-display: fallback;
  src: url("GothaProBol.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
